<template>
  <div class="position-relative">
    <canvas :id="id" height="300"></canvas>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      required: true,
      type: String,
    },
    chartData: {
      required: true,
      type: Array,
    },
    label: {
      required: true,
      type: Array,
    },
    text: {
      require: false,
    },
    filterValue: {
      require: false,
      type: String,
    },
    xText: {
      require: false,
    },
    type: {
      required: false,
    },
    usePercent: {
      required: false,
      default: false,
      type: Boolean,
    },
    customTooltipLabel: {
      required: false,
      type: Boolean | Function,
      default() {
        const $ = this;
        function label(tooltipItem, data) {
          var datasetLabel =
            data.datasets[tooltipItem.datasetIndex].label || "Other";
          return datasetLabel + ": " + $.addCommas(tooltipItem.value);
        }
        return label;
      },
    },
    customTooltipTitle: {
      required: false,
      type: Boolean | Function,
      default() {
        const $ = this;

        function title(tooltipItem, data) {
          if ($.xText)
            return `${$.xText} (${data.labels[tooltipItem[0].index]})`;
          else return `${data.labels[tooltipItem[0].index]}`;
        }
        return title;
      },
    },
    customXTicks: {
      required: false,
      type: Function,
      default: undefined,
    },
    customYTicks: {
      required: false,
      type: Function,
      default: undefined,
    },
  },
  mounted() {
    this.initChart();
  },
  methods: {
    addCommas(nStr) {
      var x, x1, x2;

      nStr += "";
      x = nStr.split(".");
      x1 = x[0];
      x2 = x.length > 1 ? "." + x[1] : "";
      var rgx = /(\d+)(\d{3})/;
      while (rgx.test(x1)) {
        x1 = x1.replace(rgx, "$1" + "," + "$2");
      }
      return x1 + x2;
    },
    async initChart() {
      var ctx = document.getElementById(this.id);
      var that = this;

      var myChart = new this.$Chart(ctx, {
        type: this.type || "line",
        data: {
          labels: this.label,
          datasets: this.chartData,
        },
        options: {
          plugins: {
            datalabels: {
              display: function (context) {
                return that.$store.state.theme.themeLogo.result
                  .show_dashboard_tooltips == "0"
                  ? "auto"
                  : false;
              },
              //   options: {
              font: {
                weight: "bold",
                size: 12,
              },
              formatter: function (value, context) {
                return value > 0
                  ? that.usePercent
                    ? that.addCommas(value) + "%"
                    : that.addCommas(value)
                  : "";
              },
              //   },
            },
          },
          tooltips: {
            mode: "index",
            intersect: false,
            callbacks: {
              title: this.customTooltipTitle,
              label: this.customTooltipLabel,
            },
          },
          hover: {
            mode: "index",
            intersect: false,
          },
          responsive: true,
          maintainAspectRatio: false,

          legend: {
            position: "bottom",
            labels: { boxWidth: 10, boxHeight: 10 },
          },

          interaction: {
            mode: "index",
          },
          scales: {
            yAxes: [
              {
                scaleLabel: {
                  display: true,
                  fontStyle: "bold",
                  labelString: this.text,
                  fontFamily: "Kanit-Regular",
                },
                ticks: {
                  callback: this.customYTicks || this.customTicksYDefault,
                  autoSkip: true,
                  min: 0,
                  max: this.usePercent ? 100 : undefined,
                  beginAtZero: true,
                  stepSize: this.usePercent ? 10 : undefined,
                  suggestedMin: 0,
                  suggestedMax: 10,
                  fontSize: 10,
                },
              },
            ],
            xAxes: [
              {
                ticks: {
                  maxRotation: 20,
                  fontSize: 10,
                  callback: this.customXTicks || this.customTicksDefault,
                },

                scaleLabel: {
                  display: this.xText == "" ? false : true,
                  fontStyle: "bold",
                  labelString: this.xText || "",
                  fontFamily: "Kanit-Regular",
                },
              },
            ],
          },
        },
      });
    },
    roundNumber() {
      let max = Math.max.apply(Math, this.chartData[0].data);

      return;
    },
    customLabel(tooltipItem, data) {
      // if (tooltipItem.value > 0) {
      var datasetLabel =
        data.datasets[tooltipItem.datasetIndex].label || "Other";
      if (data.datasets[tooltipItem.datasetIndex].value) {
        let numData =
          data.datasets[tooltipItem.datasetIndex].value[tooltipItem.index];
        return `${datasetLabel} : ${numData} (${tooltipItem.value}%)`;
      } else {
        return `${datasetLabel} :${tooltipItem.value}`;
      }
      // }
    },
    customTitle(tooltipItem, data) {
      return `${data.labels[tooltipItem[0].index]}`;
    },
    destroyChart() {},
    customTicksDefault(val, index) {
      return val;
    },
    customTicksYDefault(val, index) {
      return this.addCommas(val);
    },
  },
};
</script>

<style lang="scss" scoped></style>
